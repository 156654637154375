import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Video from "../components/video";

import "../style/normalize.css";
import "../style/all.scss";

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle}>
      <article className="post-content page-template">
        <div className="post-content-body">
          <figure className="kg-card kg-image-card kg-width-card">
            <Img
              fluid={data.media.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption></figcaption>
          </figure>
          <h4>Shostakovich Prelude for Two Cellos and Piano</h4>
          <Video
            videoSrcURL="https://www.youtube.com/embed/xXkbfVBmVfQ"
            videoTitle="Shostakovich Prelude for Two Cellos and Piano"
          />
          <h4>Bach Cello Suite No. 5, Gigue</h4>
          <Video
            videoSrcURL="https://www.youtube.com/embed/XCqWeXfVNgA"
            videoTitle="Bach Cello Suite No. 5, Gigue"
          />
        </div>
      </article>
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    media: file(relativePath: { eq: "media.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
);
